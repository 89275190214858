import { Disclosure } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import { AppTeamSelector } from "./AppTeamSelector";
import { BackToPrevious } from "./BackToPrevious";
import NavigationBarMobileMenu from "./NavigationBarMobileMenu";
import NavigationBarMobileMenuButton from "./NavigationBarMobileMenuButton";
import NavigationBarProfileDropdown from "./NavigationBarProfileDropdown";
import { NavigationItem, UserNavigationItem } from "../models";
import { SelectedTeams } from "feature/teams/models";
import {
  useCurrentUserQuery,
  useCurrentUserTeamsQuery,
  useDashboardUserQuery,
} from "feature/user/queries";
import { useAppContext } from "providers/AppContextProvider";
import { notEmpty } from "shared/utilities";
import nextFutureImageLoader from "shared/utilities/nextFutureImageLoader";

const NavigationLogo = () => (
  <div className="ml-[8px]">
    <Image
      src="/v1698342424/compound-logo-sm.svg"
      loader={nextFutureImageLoader}
      height={28}
      width={28}
      alt="Compound Planning logo"
    />
  </div>
);

export const BaseNavigation = () => (
  <Disclosure as="nav" className="border-b border-gray-200 bg-white">
    <div className="max-w-7xl px-4">
      <div className="flex h-16 items-center justify-between">
        <Link href="/">
          <NavigationLogo />
        </Link>
      </div>
    </div>
  </Disclosure>
);

interface DataProps {
  readonly navigation: NavigationItem[];
  readonly userNavigation: UserNavigationItem[];
  readonly sideBarToggleClicked: () => void;
  readonly backToPreviousLink?: { href: string; label: string };
  readonly selectedTeams: SelectedTeams;
  readonly setSelectedTeams: (selected: SelectedTeams) => void;
}

export const NavigationBar = (props: DataProps) => {
  const { navigation, userNavigation } = props;

  const { entitlements, featureFlags } = useAppContext();
  const { data: currentUserData } = useCurrentUserQuery();
  const { data: currentUserTeamsData } = useCurrentUserTeamsQuery();

  // Make sure that the user has a session with the client dashboard
  useDashboardUserQuery(
    entitlements.includes("CompoundAccess") &&
      currentUserData?.type === "employee" &&
      notEmpty(currentUserData.user.dashboardId)
  );

  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white md:h-[var(--nav-bar-height)]">
      {({ open }) => (
        <>
          <div className="mx-auto pr-4 pl-2">
            <div className="flex justify-between md:h-[var(--nav-bar-height)]">
              <div className="flex items-center md:space-x-5">
                <Link href="/">
                  <NavigationLogo />
                </Link>
                {props.backToPreviousLink && (
                  <BackToPrevious
                    href={props.backToPreviousLink.href}
                    label={props.backToPreviousLink.label}
                  />
                )}
                {/* If the user is on more than 1 team, we will show the team selector */}
                {featureFlags["teams-access-control"] === true &&
                  currentUserTeamsData &&
                  currentUserTeamsData.items.length > 1 && (
                    <AppTeamSelector
                      selectedTeams={props.selectedTeams}
                      setSelectedTeams={props.setSelectedTeams}
                    />
                  )}
              </div>
              <div className="hidden md:flex md:items-center">
                <NavigationBarProfileDropdown userNavigationItems={userNavigation} />
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Right side bar open/close button - visible below breakpoint - wraps a disclosure button*/}
                <NavigationBarMobileMenuButton open={open} />
              </div>
            </div>
          </div>

          {/* Wraps a disclosure panel corresponding to the mobile button - visible like the button below the breakpoint */}
          <NavigationBarMobileMenu navigationItems={navigation} />
        </>
      )}
    </Disclosure>
  );
};
